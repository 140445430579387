<template>
  <v-container fluid class="pa-0 ma-0 fill-height text-left">
    <v-row justify="start">
      <v-col cols="12">
        <v-card class="d-flex fill-height fluid pa-0 ma-0" tile width="100%">
          <v-scroll-x-transition appear>
            <v-img src="@/assets/images/hospital-angeles.jpg" transition="slide-x-transition" position="top"
              width="100%" height="300" max-height="300">
              <v-container fluid class="fill-height justify-center align-end bottom-gradient">
                <v-row align="center" justify="center">
                  <v-col>
                    <div class="white--text pb-10">
                      <span class="pl-1 pl-md-8 text-h5 text-md-h4 text-uppercase font-weight-black"
                        style="text-shadow: 2px 2px 10px black">
                        Our reliable Hospital Catalog
                      </span>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-img>
          </v-scroll-x-transition>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <div class="boxdivider"></div>
    </v-row>

    <v-row justify="center">
      <SearchBar :data="hospitals" @search="updateFilteredData" />
      <FilterStateMunicipality @filter-data="filterEvent" @filter-data-name="filterEventName" />
    </v-row>

    <v-row justify="center" align="center">
      <div class="fill-height container--fluid mt-8 mb-8 mx-5" style="width: 100%">
        <v-container fluid class="fill-height">
          <v-row justify="start" align="center">
            <v-col v-for="(item, index) in filteredData" :key="index" class="d-flex child-flex mb-5" cols="12" sm="6"
              md="4">
              <CardHospital :item="item" :key="index" />
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-row>
    <v-row justify="center" class="ma-10" v-if="loading">
      <div style="text-align: center;">
        <div>
          Loading our list of excellence hospitals, please be patient ...
        </div>
        <div style="display: inline-block;margin-top: -15px;">
          <v-img src="@/assets/images/loading_new.gif" width="100px"></v-img>
        </div>
      </div>
    </v-row>
    <v-row justify="center" class="ma-10" v-else-if="!loading && hospitals.length === 0">
      <div style="text-align: center;">
        <div>
          There are no Hospitals in the catalogue...
        </div>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import hospitalsService from "@/providers/HospitalsService";
import SearchBar from "../searchBar/SearchBar.vue";
import FilterStateMunicipality from "@/components/filter-state-municipality/FilterStateMunicipality";
import CardHospital from "@/components/card-hospital/CardHospital.vue";

export default {
  name: "HospitalsComponent",

  components: {
    SearchBar,
    FilterStateMunicipality,
    CardHospital
  },

  data: () => ({
    selectedId: 0,
    hospitals: [],
    filteredData: [],
    loading: true,
    transparent: "rgba(255, 255, 255, 0)",
    message: "Loading our list of excellence hospitals, please be patient ...",
  }),
  methods: {
    filterEventName(value) {
      let s = "";
      if (value.province) {
        s = `${value.province}, México `
      }
      if (value.municipality) {
        s = `${value.municipality}, ${s}`
      }
      if (s !== "") {
        s = `of ${s}`;
      }
      document.title = `Top 10 Hospitals ${s}| Baja California Health Tourism`;
    },
    filterEvent(value) {
      this.getHospitals(value.province, value.municipality);
    },
    getHospitals(province='', municipality='') {
      this.loading = true;
      hospitalsService.getViewList(true, province, municipality).then((record) => {
        this.hospitals = record.value;
        this.filteredData = [...this.hospitals];

        if (this.hospitals.length === 0) {
          this.message = "There are no Hospitals in the catalogue...";
        }
      }).catch(()=>{
        this.message = "There are no Hospitals in the catalogue...";
      }).finally(()=>{
        this.loading = false;
      });
    },

    updateFilteredData(filteredData) {
      this.filteredData = filteredData;
    },
  },
  mounted() {
    this.hospitals = [];
    this.filteredData = [];
  },
};
</script>

<style scoped>
 
.bottom-gradient {
  background-image: linear-gradient(
    to bottom,
    rgba(163, 153, 248, 0.295) 0%,
    rgba(101, 99, 248, 0.699) 100%
  );
}
#my-lax .v-parallax__content {
  background: linear-gradient(45deg, black, transparent);
}
.v-btn {
  text-transform: none !important;
}
.blend-opacity {
  opacity: 0.5;
  transition: opacity 0.4s ease-in-out;
  background-color: black;
  background-blend-mode: normal;
}
.blend-opacity:hover {
  opacity: 0.9;
  transition: opacity 0.4s ease-in-out;
  background-color: black;
  background-blend-mode: darken;
}
.boxdivider {
  background-color: transparent;
  width: 100px;
  height: 50px;
  color: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom-width: 3px;
  border-bottom-color: blueviolet;
  border-bottom-style: solid;
  display: block;
}
</style>
